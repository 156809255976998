<template>
  <v-app>
    <v-card>
      <v-card-title>Crear producto o bundle</v-card-title>
      <v-card-text class="d-flex justify-space-around">
        <v-radio-group label="Seleccione el tipo:" v-model="mode" row>
          <v-radio label="Producto" value="product"></v-radio>
          <v-radio label="Bundle" value="bundle"></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-text v-if="mode == 'product'">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="product.name" :rules="nameRules" label="Nombre" required></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="product.sku" :rules="nameRules" label="SKU" required></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field v-model="product.ext_sku" label="SKU externo" required></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="product.ean" :rules="nameRules" label="EAN" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="product.price"
              :rules="priceRules"
              label="Precio"
              suffix="$"
              required
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="category"
              :items="categoriesList"
              :rules="categoryRules"
              label="Categoría"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
                :items="brandsList"
                v-model="product.brand"
                label="Marca"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="product.high"
              :rules="priceRules"
              label="Altura"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="product.width"
              :rules="priceRules"
              label="Ancho"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="product.depth"
              :rules="priceRules"
              label="Profundidad"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="product.weight"
              :rules="priceRules"
              label="Peso"
              required
              suffix="kg"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <img style="max-width:100%; width:150px;" v-bind:src="product.imagecover_url_preview" /> 
          </v-col>
          <v-col cols="12" md="3">
            <v-row>
              <v-col cols="12" md="12">
                <v-file-input
                  v-model="product.imagecover"
                  color="deep-purple accent-4"
                  label="Subir Foto Producto"
                  placeholder="Sube una archivo jpeg o png."
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  @change="uploadFileProduct()"
                  :disabled="loading"
                  :clearable="true"
                  >
                      <!-- <template v-slot:selection="{ index, text }">
                          <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                          >
                          {{ text }}
                          </v-chip>
                      </template> -->
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <!-- <v-col col="12" md="12">
                <v-text-field v-model="product.imagecover_url" label="Url Imagen"></v-text-field>
                <v-text-field v-model="product.imagecover_url_json" label="JSON"></v-text-field>
              </v-col> -->
            </v-row>
            
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12" md="4">
            <v-btn color="primary" :disabled="loading" @click="sendFormProduct">Enviar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="mode == 'bundle'">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="bundle.name" :rules="nameRules" label="Nombre" required></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="bundle.sku" :rules="nameRules" label="SKU" required></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field v-model="bundle.ext_sku" label="SKU externo" required></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="bundle.ean" :rules="nameRules" label="EAN" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="bundle.price"
              :rules="priceRules"
              label="Precio"
              suffix="$"
              required
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="bundle.category"
              :items="categoriesList"
              :rules="categoryRules"
              label="Categoría"
              required
            ></v-select>
          </v-col>
           <v-col cols="12" md="3">
            <v-autocomplete
                :items="brandsList"
                v-model="bundle.brand"
                label="Marca"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
                :items="productsList"
                v-model="selectProducts"
                label="Productos del bundle"
                 multiple
                chips
              :return-object="true"

            >
            </v-autocomplete>
            
            <!-- <v-select
              v-model="selectProducts"
              :items="productsList"
              label="Productos del bundle"
              multiple
              chips
            ></v-select> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="bundle.high"
              :rules="priceRules"
              label="Altura"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="bundle.width"
              :rules="priceRules"
              label="Ancho"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="bundle.depth"
              :rules="priceRules"
              label="Profundidad"
              required
              suffix="cm"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="bundle.weight"
              :rules="priceRules"
              label="Peso"
              required
              suffix="kg"
              step="0.001"
              min="0.000"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="2">
            <img style="max-width:100%; width:150px;" v-bind:src="bundle.imagecover_url_preview" /> 
          </v-col>
          <v-col cols="12" md="3">
            <v-row>
              <v-col cols="12" md="12">
                <v-file-input
                  v-model="bundle.imagecover"
                  color="deep-purple accent-4"
                  label="Subir Foto Producto"
                  placeholder="Sube una archivo jpeg o png."
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  @change="uploadFileProductBundle()"
                  :disabled="loading"
                  :clearable="true"
                  >
                      <!-- <template v-slot:selection="{ index, text }">
                          <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                          >
                          {{ text }}
                          </v-chip>
                      </template> -->
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col col="12" md="12">
                <!-- <v-text-field v-model="bundle.imagecover_url" label="Url Imagen"></v-text-field>
                <v-text-field v-model="bundle.imagecover_url_json" label="JSON"></v-text-field> -->
              </v-col>
            </v-row>
            
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12" md="4">
            <v-btn color="primary" :disabled="loading" @click="sendFormBundle">Enviar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
          v-show="selectProducts.length && mode == 'bundle'"
          :headers="headers"
          :items="selectProducts"
          no-results-text="No hay resultados disponibles"
          no-data-text="No hay datos disponibles"
          loading-text="Cargando datos..."
          class="elevation-1 datatable"
        >
          <template v-slot:item.quantity="{ item }">
            <td class="text-center">
              <v-text-field v-model="item.quantity" type="number" min="1"></v-text-field>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  data() {
    return {
      mode: "product",
      category: undefined,
      product: {
        name: "",
        ean: "",
        sku: "",
        price: undefined,
        high: 0.0,
        width: 0.0,
        depth: 0.0,
        weight: 0.0,
        ext_sku: undefined,
        brand: undefined,
        imagecover: undefined,
        imagecover_uploaded: false,
        imagecover_url: undefined,
        imagecover_url_preview : "https://s3.us-west-1.amazonaws.com/fullkom.oms/starkoms_placeholder.png",
        imagecover_url_json : "",
      },
      bundle: {
        name: "",
        ean: "",
        sku: "",
        price: undefined,
        products: [],
        high: 0.0,
        width: 0.0,
        depth: 0.0,
        weight: 0.0,
        brand: undefined,
        imagecover: undefined,
        imagecover_uploaded: false,
        imagecover_url: undefined,
        imagecover_url_preview: "https://s3.us-west-1.amazonaws.com/fullkom.oms/starkoms_placeholder.png",
        imagecover_url_json : "",

      },
      nameRules: [
        (v) => !!v || "El Campo es requerido",
        //v => v.length <= 10 || 'asdf',
      ],
      priceRules: [
        (v) => !!v || "El Campo es requerido",
        (v) => v > 0 || "Debes ingresar un valor mayor a 0",
      ],
      categoryRules: [
        (v) => !!v || "Debes elegir una opción"
      ],
      loading: false,
      productsList: [],
      categoriesList: [],
      brandsList: [],
      selectProducts: [],
      headers: [
        { text: "Producto", value: "text" },
        { text: "Cantidad", value: "quantity" },
      ],
      dateMsg:
        new Date().toISOString().substr(0, 10) +
        " " +
        new Date().toISOString().substr(11, 5),
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard productos", route: "/productos" },
      { title: "Crear producto o bundle", route: "/productos/crear" },
    ]);
    this.fetchProducts();
    this.fetchCategories();
    this.fetchBrands();
  },
  methods: {
    fetchProducts() {
      this.productsList = [];
      var vm = this;
      this.axios({
        url: "order/products_data_list",
        method: "GET",
      })
        .then((response) => {
          vm.productsList = response.data.map((e) => ({ ...e, quantity: 1 }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCategories(){
      this.categoriesList = [];
      var vm = this;
      this.axios({
        url: "inventory/categories_data_list",
        method: "GET",
      })
        .then((response) => {
          vm.categoriesList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchBrands(){
      this.brandsList = [];
      var vm = this;
      this.axios({
        url: "/inventory/brands_list",
        method: "GET",
      })
        .then((response) => {
          vm.brandsList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleMode() {
      if (this.mode == "bundle") {
        console.log("Se debe ejecutar fetchProducts");
        this.fetchProducts();
      }
    },
    sendFormProduct() {
      let createProduct = this.product;

      this.loading = true;
      var vm = this;
      this.axios({
        url: "order/products/product",
        method: "POST",
        data: {...vm.product, category_id: vm.category},
      })
        .then((response) => {
          vm.loading = false;
          vm.$bvToast.toast(
            `El producto: ${createProduct.name}, ha sido creado, el ${vm.dateMsg} exitosamente!`,
            {
              title: `Información`,
              variant: "success",
              solid: true,
              toaster: "b-toaster-bottom-center",
            }
          );
          //Set ALert to LocalStorage to show on the redirect page
          localStorage.setItem(
            "crearProducto",
            JSON.stringify({
              name: createProduct.name,
            })
          );
          //rediect to dashboard Products
          vm.$router.push("/productos");
        })
        .catch((error) => {
          vm.loading = false;
          console.log(error);
        });
    },
    sendFormBundle() {
      let createBundle = this.bundle;

      this.loading = true;
      var vm = this;
      this.axios({
        url: "order/products/bundle",
        method: "POST",
        data: { ...this.bundle, products: this.selectProducts },
      })
        .then((response) => {
          vm.loading = false;
          vm.$bvToast.toast(
            `El Bundle: ${createBundle.name}, ha sido creado, el ${vm.dateMsg} exitosamente!`,
            {
              title: `Información`,
              variant: "success",
              solid: true,
              toaster: "b-toaster-bottom-center",
            }
          );

          localStorage.setItem(
            "crearBundle",
            JSON.stringify({
              name: createBundle.name,
            })
          );

          //rediect to dashboard Products
          vm.$router.push("/productos");
        })
        .catch((error) => {
          vm.loading = false;
          console.log(error);
        });
    },
    uploadFileProduct()
    {
      let product = this.product;
        if(this.product.imagecover instanceof File && !this.loading){
            console.log('se tiene que subir el archivo');
            this.loading = true;
            this.$nextTick(() => {
                let formData = new FormData();
                formData.append("file_image_cover", this.product.imagecover, this.product.imagecover.name);
                var vm = this;
                this.axios({
                    url: 'inventory/products/upload_cover_image',
                    data: formData,
                    method: 'POST'
                }).then( response => {
                  console.log(response);
                  console.log(JSON.stringify(response.data));
                  
                    // vm.product.url = response.data;
                    product.imagecover_url_preview = response.data.image_1000;
                    product.imagecover_url = response.data.image_full;
                    product.imagecover_url_json = JSON.stringify(response.data);

                    vm.product.imagecover_uploaded = true;
                    vm.loading = false;
                    console.log('keweaeae');
                    vm.$bvToast.toast(
                        'Archivo subido con exito. Ahora puedes confirmar la carga presionanao el boton "Guardar"',
                        {
                            title: `Notificación`,
                            variant: "success",
                            solid: true,
                            toaster: "b-toaster-bottom-center",
                        }
                    );
                    
                }).catch( error => {
                    vm.loading = false;
              });
            })
        }
    },
    uploadFileProductBundle()
    {
      let bundle = this.bundle;
        if(this.bundle.imagecover instanceof File && !this.loading){
            console.log('se tiene que subir el archivo');
            this.loading = true;
            this.$nextTick(() => {
                let formData = new FormData();
                formData.append("file_image_cover", this.bundle.imagecover, this.bundle.imagecover.name);
                var vm = this;
                this.axios({
                    url: 'inventory/products/upload_cover_image',
                    data: formData,
                    method: 'POST'
                }).then( response => {
                  console.log(response);
                  console.log(JSON.stringify(response.data));
                  
                    // vm.product.url = response.data;
                    bundle.imagecover_url_preview = response.data.image_1000;
                    bundle.imagecover_url = response.data.image_full;
                    bundle.imagecover_url_json = JSON.stringify(response.data);

                    vm.bundle.imagecover_uploaded = true;
                    vm.loading = false;
                    console.log('keweaeae');
                    vm.$bvToast.toast(
                        'Archivo subido con exito. Ahora puedes confirmar la carga presionanao el boton "Guardar"',
                        {
                            title: `Notificación`,
                            variant: "success",
                            solid: true,
                            toaster: "b-toaster-bottom-center",
                        }
                    );
                    
                }).catch( error => {
                    vm.loading = false;
              });
            })
        }
    },
  },
};
</script>